import React, { memo, useCallback, useEffect } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Container } from "./App";
import { NewChart } from "../svg/newChart";
import { useSelector } from "react-redux";
import { useAction, useStore } from "../redux/reduxStore.js";
import { ajax } from "../utils/utils.js";
import Games from "./Games";
import { gameConfirm } from "./Overlays.js";

import "../css/profile.scss";
import { Button } from "react-bootstrap";
import { useNamespacedTranslation } from "./components/LanguageSelector";
import { useGetLanguage } from "./components/LanguageSelector";

// PlayYourTest questions
export default function Profile() {
  const { t } = useNamespacedTranslation();
  const [profileAreas, setProfileAreas] = useStore("profileAreas");
  const loginToken = useSelector((state) => state.loginToken);
  const toReplayTest = useSelector((state) =>
    state.replay.some((f) => f.includes("test"))
  );
  const { progress } = useParams();
  const goTo = useNavigate();
  const setProgressBeforeReplay = useAction("setProgressBeforeReplay");
  useEffect(() => {
    (async () => {
      if (!profileAreas || !profileAreas.profile) {
        const json = await ajax.json(
          "php/calculateAreas.php",
          `loginToken=${loginToken}`
        );
        if (json) setProfileAreas(json);
      }
    })();
  }, [loginToken, profileAreas, setProfileAreas]);
  const profile = useProfile(profileAreas);
  return (
    <Container>
      {toReplayTest ? (
        <Button
          variant="primary"
          size="lg"
          style={{ marginTop: "5rem" }}
          onClick={() =>
            ajax
              .json(
                "php/saveProgressBeforeReplay.php",
                `loginToken=${loginToken}`
              )
              .then(() => {
                setProgressBeforeReplay("profile/" + progress);
                goTo("./../../pyt");
              })
              .catch(() => alert("Errore nel reimpostare il test"))
          }
        >
          {t("profile.repeat")}
        </Button>
      ) : (
        profileAreas &&
        profileAreas.profile && (
          <>
            <div className="titleAndDownload">
              <h1>
                {t("profile.own")}
                {profile.toUpperCase()}
              </h1>
              <ProfileLink />
            </div>
            <Routes>
              <Route path="powered" element={<AreaTable area="Powered" />} />
              <Route path="leader" element={<AreaTable area="Leader" />} />
              <Route path="accurate" element={<AreaTable area="Accurate" />} />
              <Route path="easy" element={<AreaTable area="Easy" />} />
            </Routes>
            {/* <ChartStats {...profileAreas} /> */}
            <NewChart {...profileAreas} />
          </>
        )
      )}
      <Games />
    </Container>
  );
}

// Make profile link for pdf
export const ProfileLink = () => {
  const { t } = useNamespacedTranslation();
  const language = useGetLanguage();
  const goTo = useNavigate();
  const profileAreas = useSelector((state) => state.profileAreas);
  const loginToken = useSelector((state) => state.loginToken);
  const profile = useProfile(profileAreas);
  return !profileAreas || !profileAreas.profile ? (
    <div></div>
  ) : (
    <a
      className="downloadProfile"
      href={`./profiles/${language}/PLAY40_2021_${profile.toUpperCase()}.pdf`}
      target="_blank"
      rel="noopener noreferrer"
      title="download profile"
    >
      {t("profile.download")}
      <img src="pdf-icon.png" alt="download profile" />
    </a>
    /* <>
      <div style={{ width: "100%", height: 0 }}></div>
      <Button
        style={{ margin: "0px 0.5rem" }}
        variant="primary"
        size="lg"
        onClick={() => {
          goTo("receive_mail");
          gameConfirm().then((confirmed) => {
            if (confirmed && window.$SEND_MAIL) {
              ajax
                .json(
                  "php/mail/sendProfile.php",
                  new URLSearchParams({
                    mail: window.$SEND_MAIL,
                    profile: profileAreas.profile.toUpperCase(),
                    token: loginToken,
                  }).toString()
                )
                .then((response) => {
                  if (response === null) {
                    goTo("mail_error");
                  } else {
                    goTo("mail_success");
                  }
                });
              //.catch(() => goTo("mail_error"));
            }
          });
        }}
      >
        Scarica il tuo profilo completo per vedere tutti i dettagli
      </Button>
    </> */
  );
};

// Area Table overlay
const AreaTable = memo((props) => {
  const { t } = useNamespacedTranslation();
  const goTo = useNavigate();
  const close = useCallback(() => goTo("../"), [goTo]);
  return (
    <div className={`areaTable ${props.area}`}>
      <table>
        <thead>
          <tr>
            <th colSpan="2">
              {props.area === "Easy" ? (
                <>
                  <div className="Eas">Eas</div>
                  <span>Y</span>
                </>
              ) : (
                <>
                  <span>{props.area[0]}</span>
                  {props.area.substr(1)}
                </>
              )}
              <div className="titleDescription">
                <h4>{t("profile.title")}</h4>
                {t("profile.description")}
              </div>
              <div className="closeTable" onClick={close}>
                X
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          dangerouslySetInnerHTML={{ __html: t("profile.areas." + props.area) }}
        />
      </table>
    </div>
  );
});

function useProfile(profileAreas) {
  const { t } = useNamespacedTranslation();
  return profileAreas.profile ? t(`profiles.${profileAreas.profile}`) : null;
}
