import { store } from "../redux/reduxStore";

// update progress
export function updateProgress(
  progress = "",
  feature = "",
  param = [],
  value = []
) {
  // avoids useless updates if two tabs are opened
  // this is disabled now because it causes a bug if player is letting time reach 0 while doing something else
  // if(document.hidden) return Promise.resolve(null);
  // convert to array if necessary
  if (!Array.isArray(param)) param = [param];
  if (!Array.isArray(value)) value = [value];
  // get loginToken if not passed
  const loginToken = store.getState().loginToken;
  // append feature data if present
  const appendFeature = feature
    ? `&feature=${feature}${param
        .map((p) => `&param[]=${encodeURIComponent(p)}`)
        .join("")}${value
        .map((v) => `&value[]=${encodeURIComponent(v)}`)
        .join("")}`
    : "";
  // body request
  const body = `hash=${loginToken}${
    progress ? "&progress=" + progress : ""
  }${appendFeature}`;
  // send ajax
  return ajax("php/updateProgress.php", body).then((resp) =>
    // push the player out if the server says to do so
    resp.includes("push_player_out") ? window.location.reload() : resp
  );
}

// ajax post
export async function ajax(url, query, type = "text") {
  const resp = await fetch(url, {
    method: "post",
    body:
      typeof query === "string"
        ? query
        : new URLSearchParams({
            ...query,
          }),
    headers: { "Content-Type": "application/x-www-form-urlencoded;" },
  });
  return resp.ok ? await resp[type]() : null;
}

// json ajax post; ignore errors
ajax.json = async (url, query) => {
  try {
    return await ajax(url, query, "json");
  } catch (err) {
    return null;
  }
};

// generate random string
export function generateRandomString(length = 6) {
  return Math.random().toString(20).substr(2, length);
}

// try to decode json without erroring
export function tryJson(json) {
  try {
    return JSON.parse(json);
  } catch (err) {
    return null;
  }
}
