import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useStore } from "../redux/reduxStore";
import { ChangeLanguage } from "./components/LanguageSelector";
import { useNamespacedTranslation } from "./components/LanguageSelector";
import { useState } from "react";

// General overlay;
// in order for this to work correctly, you have to define a lot of subpaths; besides all urls in main router must finish with /*
function Overlay(props) {
  const { t } = useNamespacedTranslation();
  const location = useLocation(); // Link would manage to get previous url by ../, button onClick can't, so we need the location
  const goTo = useNavigate();
  const exit = useCallback(
    (confirmed = false) => {
      goTo(location.pathname + "/../");
      document.dispatchEvent(
        new CustomEvent("gameConfirm", { detail: { confirmed: confirmed } })
      );
    },
    [goTo, location]
  );
  const ok = useCallback(() => exit(true), [exit]);
  const cancel = useCallback(() => exit(), [exit]);
  const ref = useRef();
  const help = useMemo(
    () => (
      <div className="help" ref={ref}>
        {props.children}
        {props.confirm && (
          <Button variant="primary" onClick={ok}>
            {props.confirmText ?? "OK"}
          </Button>
        )}
        {props.closeButton?.(cancel) || (
          <Button variant="primary" onClick={cancel}>
            {props.confirm ? t("overlay.undo") : t("overlay.close")}
          </Button>
        )}
      </div>
    ),
    [
      props.children,
      props.confirm,
      props.confirmText,
      props.closeButton,
      ok,
      cancel,
      t,
    ]
  );
  // make closable by clicking the document
  useEffect(() => {
    if (ref.current) {
      const outerExit = (e) => !e.target.closest(".help") && exit();
      document.addEventListener("click", outerExit);
      return () => document.removeEventListener("click", outerExit);
    }
  }, [exit, ref]);
  return (
    <Routes>
      <Route path={props.path} element={help} />
      <Route path={"/:a/" + props.path} element={help} />
      <Route path={"/:a/:b/" + props.path} element={help} />
      <Route path={"/:a/:b/:c/" + props.path} element={help} />
      <Route path={"/:a/:b/:c/:d/" + props.path} element={help} />
      <Route path={"/:a/:b/:c/:d/:e/" + props.path} element={help} />
      <Route path={"/:a/:b/:c/:d/:e/:f/" + props.path} element={help} />
      <Route path={"/:a/:b/:c/:d/:e/:f/:g/" + props.path} element={help} />
    </Routes>
  );
}

// Show help overlay
export function Help() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="help">
      <p>
        {t("overlay.help")}
        <a href="mailto:info@laborplay.com">
          <br />
          info@laborplay.com
        </a>
      </p>
    </Overlay>
  );
}

// Show valid overlay
export function AlertValid() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="alert_valid">
      <p>{t("overlay.alert_valid")}</p>
    </Overlay>
  );
}

// Show terms overlay
export function AlertTerms() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="alert_terms">
      <p>{t("overlay.alert_terms")}</p>
    </Overlay>
  );
}

// Mail overlay
export function ShowTerms() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="show_terms">
      <h3>{t("terms.title")}</h3>
      <div
        className="terms"
        dangerouslySetInnerHTML={{ __html: t("terms.text_nominale_footer") }}
      />
    </Overlay>
  );
}

// Show confirm overlay
export function ConfirmVincolato() {
  const { t } = useNamespacedTranslation();
  function getText() {
    return t("overlay.confirm_vincolato").replace(
      "20",
      Math.round((window._confirm_vincolato_time_ || 1200) / 60)
    );
  }
  const [text, setText] = useState(getText());

  useEffect(() => {
    function changeText() {
      setText(getText());
    }
    document.addEventListener("_confirm_vincolato_", changeText);
    return () =>
      document.removeEventListener("_confirm_vincolato_", changeText);
  }, []);

  return (
    <Overlay path="confirm_vincolato" confirm={true}>
      <p>{text}</p>
    </Overlay>
  );
}

// Show confirm overlay
export function ConfirmLibero() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="confirm_libero" confirm={true}>
      <p>{t("overlay.confirm_libero")}</p>
    </Overlay>
  );
}

// Mail overlay
export function ReceiveMail() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="receive_mail" confirm confirmText={"CONFERMA"}>
      <p>{t("overlay.receive_mail")}</p>
      <input
        style={{ padding: "5px", marginBottom: "20px", marginTop: "10px" }}
        onChange={(e) => {
          window.$SEND_MAIL = e.target.value;
        }}
        type="text"
        placeholder={t("overlay.receive_mail_placeholder")}
      />
    </Overlay>
  );
}

// Mail success overlay
export function MailSuccess() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="mail_success">
      <p>{t("overlay.mail_success")}</p>
    </Overlay>
  );
}

// Mail error overlay
export function MailError() {
  const { t } = useNamespacedTranslation();
  return (
    <Overlay path="mail_error">
      <p style={{ color: "red" }}>{t("overlay.mail_error")}</p>
    </Overlay>
  );
}

// Show terms overlay
export function AlertLogOut() {
  const { t } = useNamespacedTranslation();
  const [loginToken] = useStore("loginToken");
  return (
    <Overlay path="alert_logout" confirm confirmText={"CONFERMA"}>
      <p>{t("overlay.alert_logout_1")}</p>
      <p>{t("overlay.alert_logout_2")}</p>
      <p>{window.location.origin + "?resume=1&hash=" + loginToken}</p>
    </Overlay>
  );
}

// Show help overlay
export function LanguageOverlay() {
  return (
    <Overlay
      path="language"
      closeButton={(close) => <ChangeLanguage close={close} />}
    ></Overlay>
  );
}

// wait for game confirmation
export async function gameConfirm() {
  return new Promise((r) =>
    document.addEventListener("gameConfirm", (evt) => r(evt.detail.confirmed), {
      once: true,
    })
  );
}
