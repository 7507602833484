import React, { memo, useEffect, useCallback, useMemo, useState } from "react";
import { Link, Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useStore, useAction } from "../redux/reduxStore";
import QrReader from "react-qr-reader";
import { ajax, generateRandomString } from "../utils/utils";
import { Container } from "./App";

import "../css/login.scss";
import { gameConfirm } from "./Overlays";
import { useNamespacedTranslation } from "./components/LanguageSelector";

// Login Component
export const LoginUser = () => {
  const { t } = useNamespacedTranslation();
  const [attemptToken, setAttemptToken] = useStore("attemptToken");
  const [loginToken, setLoginToken] = useStore("loginToken");
  const [userData, setUserData] = useStore("userData");
  const [isRequesting, setIsRequesting] = useState(0);
  const reset = useAction("reset");
  const goTo = useNavigate();
  // control form with redux
  const changeAttemptToken = useCallback(
    (e) => setAttemptToken(e.target.value),
    [setAttemptToken]
  );

  // memoize whether loginToken is === 10 and so if the input must be disabled
  const isDisabled = useMemo(() => attemptToken === 10, [attemptToken]);

  // if token exists go to user/progress, else show error
  useEffect(() => {
    (async () => {
      // if it's logged, go to progress or start user page
      if (userData[0]) goTo("user/" + (userData[0].progress || ""));
      // else fetch and check, but only if token reaches 10 of length
      else if (
        ((attemptToken && attemptToken.length) === 10 || loginToken) &&
        !isRequesting
      ) {
        setIsRequesting(1); // this flag is used to avoid duplicate requests
        const json = await ajax.json(
          "php/checkToken.php",
          `loginToken=${attemptToken || loginToken}`
        );
        // then check if json is valid, which means that the user exists
        if (json) {
          const inizio_validita = !!parseInt(json[0].inizio_validita)
            ? new Date(json[0].inizio_validita).getTime()
            : null;
          const fine_validita = !!parseInt(json[0].fine_validita)
            ? new Date(json[0].fine_validita).getTime()
            : null;
          const now = Date.now();
          if (inizio_validita && inizio_validita > now)
            // still not activated
            goTo("failedAuth/wait/" + encodeURIComponent(inizio_validita));
          else if (fine_validita && fine_validita < now)
            // expired
            goTo("failedAuth/expired");
          else {
            // success -> login
            setLoginToken(json[0].hash);
            setUserData(json);
          }
        }
        // go to failed auth but only if it was an attempt
        else if (attemptToken) {
          goTo("failedAuth/invalid");
        }
        // if there is any token in localStorage, reset
        else {
          reset();
        }
      }
    })();
  }, [
    attemptToken,
    loginToken,
    setLoginToken,
    goTo,
    setUserData,
    userData,
    isRequesting,
    setIsRequesting,
    reset,
  ]);

  // return empty component if it's already logged
  return (
    <Container>
      <h1>{t("login.title")}</h1>
      <h3>{t("login.insert")}</h3>
      <Form>
        <Form.Control
          type="text"
          placeholder="a7g6gQR0sp"
          value={attemptToken}
          onChange={changeAttemptToken}
          disabled={isDisabled}
          name={generateRandomString()}
          autoComplete={generateRandomString()}
        />
      </Form>
      <h4 className="italic">{t("login.or")}</h4>
      <Link to="qr" className="flex">
        <h3>{t("login.qr")}</h3>
        <img className="qr" src="qr.png" alt="scan qr" />
      </Link>
      <QrOverlay />
    </Container>
  );
};

// Login Admin, the admin app is external
export const LoginAdmin = () => {
  return (
    <Container>
      <h1>Area Aziende</h1>
      <h3>Inserisci la password per le aziende:</h3>
      <Form action="cms/index.php" method="post">
        <input type="hidden" name="user" value="admin" />
        <input type="hidden" name="remember" value="1" />
        <input type="hidden" name="try" value="1" />
        <Form.Control
          type="password"
          name="pass"
          placeholder="password"
          required={true}
        />
        <Button variant="primary" size="lg" type="submit">
          AVANTI
        </Button>
      </Form>
    </Container>
  );
};

// Show QR overlay at the right subpath
const QrOverlay = memo((props) => {
  const { t } = useNamespacedTranslation();
  const setAttemptToken = useAction("attemptToken");
  const error = useCallback((err) => console.log(err), []);
  const scan = useCallback(
    (data) => {
      if (data) setAttemptToken(data.slice(-10));
    },
    [setAttemptToken]
  );
  return (
    <Routes>
      <Route
        path="qr"
        element={
          <div className={props.className || "qrOverlay"}>
            <QrReader
              onError={error}
              onScan={scan}
              className="qrReader"
              delay={300}
              legacyMode={false}
            />
            <Link to="/" className="qrToggle">
              {t("login.qr_back")}
            </Link>
          </div>
        }
      />
    </Routes>
  );
});

// Log out, reset redux store and localStorage, also remove hash from url
export const LogOut = memo((props) => {
  const reset = useAction("reset");
  const goTo = useNavigate();
  const logout = useCallback(
    (e) => {
      e.preventDefault();
      function __exit() {
        reset();
        window.history.replaceState({}, document.title, "./");
        goTo("/");
      }
      if (props.nodialog) {
        __exit();
      } else {
        goTo("alert_logout");
        gameConfirm().then((confirmed) => {
          if (confirmed) {
            __exit();
          }
        });
      }
    },
    [reset, goTo, props.nodialog]
  );
  return (
    <a
      href="/"
      id="clickToLogout"
      className={props.className || ""}
      onClick={logout}
    >
      {props.children}
    </a>
  );
});

// Show message for failed authentication
export const FailedAuth = (props) => {
  const { t } = useNamespacedTranslation();
  return {
    invalid: (
      <h4 className="failedAuth">
        {t("login.failed_1")}
        <br />
        {t("login.failed_2")}
        <br />
        <LogOut>{t("login.retry")}</LogOut>.
      </h4>
    ),
    wait: (
      <h4 className="failedAuth">
        {t("login.failed_active")}
        {" " +
          new Date(
            parseInt(decodeURIComponent(useParams().inizio_validita))
          ).toLocaleDateString() +
          " "}
        <LogOut>{t("login.exit")}</LogOut>.
      </h4>
    ),
    expired: (
      <h4 className="failedAuth">
        {t("login.failed_expired")} <LogOut>{t("login.exit")}</LogOut>.
      </h4>
    ),
  }[useParams().type];
};
