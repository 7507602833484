export default {
  title: "Información sobre el tratamiento de datos personales",
  read1: "CONSENTIMIENTO EN VIRTUD DEL ART. 7 DEL REGLAMENTO UE 2016/679",
  read2:
    "El abajo firmante declara haber entendido completamente la información proporcionada por Bracco como Controlador de datos y declara que:",
  read3:
    "consentimiento para el procesamiento para los fines mencionados en la información, incluida la elaboración de perfiles",
  read4:
    "En caso de que no tenga que dar su consentimiento, no podrá utilizar el servicio ofrecido por el Responsable del Tratamiento.",
  text_nominale_footer: `
  <h6> 1. ¿QUÉ ES EL TRATAMIENTO DE DATOS PERSONALES?</h6>
  <p>
  El tratamiento de los datos personales consiste en la recopilación, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, uso, comunicación, difusión, comparación o interconexión, limitación, cancelación o destrucción de dichos datos.
  </p>
  <h6> 2. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE MIS DATOS PERSONALES?</h6>
  <p>
  El Controlador de datos es Bracco Imaging spa, con domicilio social en Via Folli, 50 (MI).
  Puede ponerse en contacto con el responsable de la protección de datos ("DPO") a través de la dirección de correo electrónico <a href="mailto:dpo@bracco.com"> dpo@bracco.com </a>.
  </p>
  <h6> 3. ¿CON QUÉ FINES SE TRATAN MIS DATOS?</h6>
  <p>
  Se tratan para ejecutar el servicio ofrecido por el Responsable: el tratamiento de Tus datos tiene como finalidad permitirte el acceso a experiencias de pruebas y en vídeo lúdicas para mapear habilidades blandas y devolver datos de rendimiento sobre competencias específicas; enviarte comunicaciones inherentes al servicio mismo; cumplir con las obligaciones legales y cumplir con las solicitudes procedentes de las Autoridades.
  </p>
  <h6> 4. ¿CUÁLES SON LAS BASES LEGALES PARA EL TRATAMIENTO DE MIS DATOS?</h6>
  <p>
  La base legal del tratamiento es el consentimiento. En caso de no prestar el consentimiento, no podrás utilizar el servicio ofrecido por el Responsable del Tratamiento. Tienes derecho a revocar en cualquier momento el consentimiento prestado. La revocación del consentimiento no afecta la legalidad del procesamiento basado en el consentimiento otorgado antes de la revocación.
  </p>
  <h6> 5. ¿QUÉ DATOS PERSONALES SE TRATAN?</h6>
  <p>
  Los datos definidos como «comunes», como por ejemplo, datos personales, el correo electrónico, el género o el año de nacimiento. También se tratan datos relacionados con los hábitos o preferencias de juego, como se especifica en el apartado 8 a continuación. No se tratan los datos inherentes a las «cookies».
  </p>
  <p>
  No se tratarán los datos de menores. El Responsable del Tratamiento no utiliza «cookies», por lo tanto, no se tratarán los datos procedentes del uso de las mismas.
  </p>
  <h6> 6. ¿QUÉ OCURRE SI NO FACILITO MIS DATOS PERSONALES?</h6>
  <p>
  Si no facilitas tus datos personales, no podrás utilizar el servicio que ofrece el Responsable.
  </p>
  <h6> 7. ¿A QUIÉN SE COMUNICARÁN MIS DATOS?</h6>
  <p>
  Dentro de los límites pertinentes a las finalidades de tratamiento contempladas en la presente Nota Informativa, tus datos podrán ser comunicados a:
  </p>
  <ul>
  </li>la Empresa responsable del tratamiento, con la que el Responsable ha estipulado el contrato de uso de la web o aplicación;</li>
  </li>empleados o colaboradores del Responsable designados como «personas autorizadas»;</li>
  </li>autoridades judiciales o administrativas para cumplir con las obligaciones legales;</li>
  </li>sujetos debidamente designados por el Responsable como «Encargados del Tratamiento» o que presten servicios funcionales para los fines indicados anteriormente;</li>
  </li>sujetos que traten los datos para ejecutar obligaciones legales específicas.</li>
  </ul>
  <p> Tus datos NO serán objeto de divulgación. </p>
  <p> Tus datos NO se transferirán a ningún país que no pertenezca a la Unión Europea ni a ninguna organización internacional; </p>
  <h6> 8. ¿CÓMO SE TRATAN MIS DATOS? ¿SE UTILIZAN PARA CREAR PERFILES? </h6>
  <p>
  El tratamiento de tus datos personales se realiza mediante un soporte informático (incluidos dispositivos portátiles) de conformidad con lo dispuesto en la legislación vigente.
  </p>
  <p>
  Tus datos personales estarán sujetos a la elaboración de perfiles solo con tu consentimiento expreso. La elaboración de perfiles se obtiene a través del uso por parte del Usuario de la aplicación PlayYourJob que, en función de tus preferencias de juego, es adecuada para asignar habilidades interpersonales o devolver datos de rendimiento sobre competencias específicas.
  </p>
  <h6> 9. ¿DÓNDE Y POR CUÁNTO TIEMPO SE CONSERVARÁN MIS DATOS?</h6>
  <p>
  Tus datos se almacenarán en la «nube» en servidores ubicados dentro de la Unión Europea y se conservarán durante el tiempo que sea necesario para la consecución de los fines indicados.
  </p>
  <p>
  Si eres candidato, tus datos se conservarán durante el período estrictamente necesario para lograr los fines para los que fueron recopilados y, en cualquier caso, durante un período no superior a 3 años. Transcurrido el plazo mencionado anteriormente, tus datos se eliminarán.
  </p>
  <p>
  Si eres empleado, tus datos se conservarán durante toda la duración de la relación laboral y también posteriormente para el cumplimiento de todas las obligaciones legales. Transcurrido el plazo mencionado anteriormente, tus datos se eliminarán.
  </p>
  <h6> 10. ¿A QUÉ TENGO DERECHO DE ACUERDO CON LA LEGISLACIÓN VIGENTE EN MATERIA DE PRIVACIDAD?</h6>
  <p>
  Tienes derecho a::
  </p>
  <ul>
  <li>acceder a tus datos personales y a la información relacionada con los mismos;</li>
  <li>rectificar datos inexactos o proporcionar datos incompletos;</li>
  <li>eliminar tus datos personales (en caso de comprobarse algunas de las condiciones señaladas en el art. 17, párr. 1 del RGPD, y en cumplimiento de las excepciones prevista en el párr. 3 del mismo artículo), sin perjuicio de lo dispuesto en el punto 9 del presente documento informativo;</li>
  <li>cualquier posible limitación del tratamiento de tus datos personales;</li>
  <li>solicitar y obtener del Responsable del Tratamiento tus datos personales en un formato estructurado y legible mediante un dispositivo automático, incluso con el fin de proporcionar dichos datos a otro responsable del tratamiento;</li>
  <li>oponerte en cualquier momento al tratamiento de tus datos personales en caso de producirse situaciones particulares que te afecten;</li>
  <li>retirar el consentimiento al tratamiento;</li>
  <li>para ejercer estos derechos, puedes ponerte en contacto con el Responsable enviando un correo electrónico a la dirección learning.development@bracco.com o por correo ordinario a la siguiente dirección Bracco Imaging spa, via Folli, 50, 20134 Milán, c/a Human Resources – People Development.</li>
  <li>En cualquier caso, el interesado siempre puede presentar una queja ante el Garante para la Protección de Datos Personales (sitio web http://www.garanteprivacy.it/).</li>
  </ul>
`,
  text_nominale: `
  <h6> 1. ¿QUÉ ES EL TRATAMIENTO DE DATOS PERSONALES?</h6>
  <p>
  El tratamiento de los datos personales consiste en la recopilación, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, uso, comunicación, difusión, comparación o interconexión, limitación, cancelación o destrucción de dichos datos.
  </p>
  <h6> 2. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE MIS DATOS PERSONALES?</h6>
  <p>
  El Controlador de datos es Bracco Imaging spa, con domicilio social en Via Folli, 50 (MI).
  Puede ponerse en contacto con el responsable de la protección de datos ("DPO") a través de la dirección de correo electrónico <a href="mailto:dpo@bracco.com"> dpo@bracco.com </a>.
  </p>
  <h6> 3. ¿CON QUÉ FINES SE TRATAN MIS DATOS?</h6>
  <p>
  Se tratan para ejecutar el servicio ofrecido por el Responsable: el tratamiento de Tus datos tiene como finalidad permitirte el acceso a experiencias de pruebas y en vídeo lúdicas para mapear habilidades blandas y devolver datos de rendimiento sobre competencias específicas; enviarte comunicaciones inherentes al servicio mismo; cumplir con las obligaciones legales y cumplir con las solicitudes procedentes de las Autoridades.
  </p>
  <h6> 4. ¿CUÁLES SON LAS BASES LEGALES PARA EL TRATAMIENTO DE MIS DATOS?</h6>
  <p>
  La base legal del tratamiento es el consentimiento. En caso de no prestar el consentimiento, no podrás utilizar el servicio ofrecido por el Responsable del Tratamiento. Tienes derecho a revocar en cualquier momento el consentimiento prestado. La revocación del consentimiento no afecta la legalidad del procesamiento basado en el consentimiento otorgado antes de la revocación.
  </p>
  <h6> 5. ¿QUÉ DATOS PERSONALES SE TRATAN?</h6>
  <p>
  Los datos definidos como «comunes», como por ejemplo, datos personales, el correo electrónico, el género o el año de nacimiento. También se tratan datos relacionados con los hábitos o preferencias de juego, como se especifica en el apartado 8 a continuación. No se tratan los datos inherentes a las «cookies».
  </p>
  <p>
  No se tratarán los datos de menores. El Responsable del Tratamiento no utiliza «cookies», por lo tanto, no se tratarán los datos procedentes del uso de las mismas.
  </p>
  <h6> 6. ¿QUÉ OCURRE SI NO FACILITO MIS DATOS PERSONALES?</h6>
  <p>
  Si no facilitas tus datos personales, no podrás utilizar el servicio que ofrece el Responsable.
  </p>
  <h6> 7. ¿A QUIÉN SE COMUNICARÁN MIS DATOS?</h6>
  <p>
  Dentro de los límites pertinentes a las finalidades de tratamiento contempladas en la presente Nota Informativa, tus datos podrán ser comunicados a:
  </p>
  <ul>
  </li>la Empresa responsable del tratamiento, con la que el Responsable ha estipulado el contrato de uso de la web o aplicación;</li>
  </li>empleados o colaboradores del Responsable designados como «personas autorizadas»;</li>
  </li>autoridades judiciales o administrativas para cumplir con las obligaciones legales;</li>
  </li>sujetos debidamente designados por el Responsable como «Encargados del Tratamiento» o que presten servicios funcionales para los fines indicados anteriormente;</li>
  </li>sujetos que traten los datos para ejecutar obligaciones legales específicas.</li>
  </ul>
  <p> Tus datos NO serán objeto de divulgación. </p>
  <p> Tus datos NO se transferirán a ningún país que no pertenezca a la Unión Europea ni a ninguna organización internacional; </p>
  <h6> 8. ¿CÓMO SE TRATAN MIS DATOS? ¿SE UTILIZAN PARA CREAR PERFILES? </h6>
  <p>
  El tratamiento de tus datos personales se realiza mediante un soporte informático (incluidos dispositivos portátiles) de conformidad con lo dispuesto en la legislación vigente.
  </p>
  <p>
  Tus datos personales estarán sujetos a la elaboración de perfiles solo con tu consentimiento expreso. La elaboración de perfiles se obtiene a través del uso por parte del Usuario de la aplicación PlayYourJob que, en función de tus preferencias de juego, es adecuada para asignar habilidades interpersonales o devolver datos de rendimiento sobre competencias específicas.
  </p>
  <h6> 9. ¿DÓNDE Y POR CUÁNTO TIEMPO SE CONSERVARÁN MIS DATOS?</h6>
  <p>
  Tus datos se almacenarán en la «nube» en servidores ubicados dentro de la Unión Europea y se conservarán durante el tiempo que sea necesario para la consecución de los fines indicados.
  </p>
  <p>
  Si eres candidato, tus datos se conservarán durante el período estrictamente necesario para lograr los fines para los que fueron recopilados y, en cualquier caso, durante un período no superior a 3 años. Transcurrido el plazo mencionado anteriormente, tus datos se eliminarán.
  </p>
  <p>
  Si eres empleado, tus datos se conservarán durante toda la duración de la relación laboral y también posteriormente para el cumplimiento de todas las obligaciones legales. Transcurrido el plazo mencionado anteriormente, tus datos se eliminarán.
  </p>
  <h6> 10. ¿A QUÉ TENGO DERECHO DE ACUERDO CON LA LEGISLACIÓN VIGENTE EN MATERIA DE PRIVACIDAD?</h6>
  <p>
  Tienes derecho a::
  </p>
  <ul>
  <li>acceder a tus datos personales y a la información relacionada con los mismos;</li>
  <li>rectificar datos inexactos o proporcionar datos incompletos;</li>
  <li>eliminar tus datos personales (en caso de comprobarse algunas de las condiciones señaladas en el art. 17, párr. 1 del RGPD, y en cumplimiento de las excepciones prevista en el párr. 3 del mismo artículo), sin perjuicio de lo dispuesto en el punto 9 del presente documento informativo;</li>
  <li>cualquier posible limitación del tratamiento de tus datos personales;</li>
  <li>solicitar y obtener del Responsable del Tratamiento tus datos personales en un formato estructurado y legible mediante un dispositivo automático, incluso con el fin de proporcionar dichos datos a otro responsable del tratamiento;</li>
  <li>oponerte en cualquier momento al tratamiento de tus datos personales en caso de producirse situaciones particulares que te afecten;</li>
  <li>retirar el consentimiento al tratamiento;</li>
  <li>para ejercer estos derechos, puedes ponerte en contacto con el Responsable enviando un correo electrónico a la dirección learning.development@bracco.com o por correo ordinario a la siguiente dirección Bracco Imaging spa, via Folli, 50, 20134 Milán, c/a Human Resources – People Development.</li>
  <li>En cualquier caso, el interesado siempre puede presentar una queja ante el Garante para la Protección de Datos Personales (sitio web http://www.garanteprivacy.it/).</li>
  </ul>
`,
  text_anonimo: `
  <h6> 1. ¿QUÉ ES EL TRATAMIENTO DE DATOS PERSONALES?</h6>
  <p>
  El tratamiento de los datos personales consiste en la recopilación, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, uso, comunicación, difusión, comparación o interconexión, limitación, cancelación o destrucción de dichos datos.
  </p>
  <h6> 2. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE MIS DATOS PERSONALES?</h6>
  <p>
  El Controlador de datos es Bracco Imaging spa, con domicilio social en Via Folli, 50 (MI).
  Puede ponerse en contacto con el responsable de la protección de datos ("DPO") a través de la dirección de correo electrónico <a href="mailto:dpo@bracco.com"> dpo@bracco.com </a>.
  </p>
  <h6> 3. ¿CON QUÉ FINES SE TRATAN MIS DATOS?</h6>
  <p>
  Se tratan para ejecutar el servicio ofrecido por el Responsable: el tratamiento de Tus datos tiene como finalidad permitirte el acceso a experiencias de pruebas y en vídeo lúdicas para mapear habilidades blandas y devolver datos de rendimiento sobre competencias específicas; enviarte comunicaciones inherentes al servicio mismo; cumplir con las obligaciones legales y cumplir con las solicitudes procedentes de las Autoridades.
  </p>
  <h6> 4. ¿CUÁLES SON LAS BASES LEGALES PARA EL TRATAMIENTO DE MIS DATOS?</h6>
  <p>
  La base legal del tratamiento es el consentimiento. En caso de no prestar el consentimiento, no podrás utilizar el servicio ofrecido por el Responsable del Tratamiento. Tienes derecho a revocar en cualquier momento el consentimiento prestado. La revocación del consentimiento no afecta la legalidad del procesamiento basado en el consentimiento otorgado antes de la revocación.
  </p>
  <h6> 5. ¿QUÉ DATOS PERSONALES SE TRATAN?</h6>
  <p>
  Los datos definidos como «comunes», como por ejemplo, datos personales, el correo electrónico, el género o el año de nacimiento. También se tratan datos relacionados con los hábitos o preferencias de juego, como se especifica en el apartado 8 a continuación. No se tratan los datos inherentes a las «cookies».
  </p>
  <p>
  No se tratarán los datos de menores. El Responsable del Tratamiento no utiliza «cookies», por lo tanto, no se tratarán los datos procedentes del uso de las mismas.
  </p>
  <h6> 6. ¿QUÉ OCURRE SI NO FACILITO MIS DATOS PERSONALES?</h6>
  <p>
  Si no facilitas tus datos personales, no podrás utilizar el servicio que ofrece el Responsable.
  </p>
  <h6> 7. ¿A QUIÉN SE COMUNICARÁN MIS DATOS?</h6>
  <p>
  Dentro de los límites pertinentes a las finalidades de tratamiento contempladas en la presente Nota Informativa, tus datos podrán ser comunicados a:
  </p>
  <ul>
  </li>la Empresa responsable del tratamiento, con la que el Responsable ha estipulado el contrato de uso de la web o aplicación;</li>
  </li>empleados o colaboradores del Responsable designados como «personas autorizadas»;</li>
  </li>autoridades judiciales o administrativas para cumplir con las obligaciones legales;</li>
  </li>sujetos debidamente designados por el Responsable como «Encargados del Tratamiento» o que presten servicios funcionales para los fines indicados anteriormente;</li>
  </li>sujetos que traten los datos para ejecutar obligaciones legales específicas.</li>
  </ul>
  <p> Tus datos NO serán objeto de divulgación. </p>
  <p> Tus datos NO se transferirán a ningún país que no pertenezca a la Unión Europea ni a ninguna organización internacional; </p>
  <h6> 8. ¿CÓMO SE TRATAN MIS DATOS? ¿SE UTILIZAN PARA CREAR PERFILES? </h6>
  <p>
  El tratamiento de tus datos personales se realiza mediante un soporte informático (incluidos dispositivos portátiles) de conformidad con lo dispuesto en la legislación vigente.
  </p>
  <p>
  Tus datos personales estarán sujetos a la elaboración de perfiles solo con tu consentimiento expreso. La elaboración de perfiles se obtiene a través del uso por parte del Usuario de la aplicación PlayYourJob que, en función de tus preferencias de juego, es adecuada para asignar habilidades interpersonales o devolver datos de rendimiento sobre competencias específicas.
  </p>
  <h6> 9. ¿DÓNDE Y POR CUÁNTO TIEMPO SE CONSERVARÁN MIS DATOS?</h6>
  <p>
  Tus datos se almacenarán en la «nube» en servidores ubicados dentro de la Unión Europea y se conservarán durante el tiempo que sea necesario para la consecución de los fines indicados.
  </p>
  <p>
  Si eres candidato, tus datos se conservarán durante el período estrictamente necesario para lograr los fines para los que fueron recopilados y, en cualquier caso, durante un período no superior a 3 años. Transcurrido el plazo mencionado anteriormente, tus datos se eliminarán.
  </p>
  <p>
  Si eres empleado, tus datos se conservarán durante toda la duración de la relación laboral y también posteriormente para el cumplimiento de todas las obligaciones legales. Transcurrido el plazo mencionado anteriormente, tus datos se eliminarán.
  </p>
  <h6> 10. ¿A QUÉ TENGO DERECHO DE ACUERDO CON LA LEGISLACIÓN VIGENTE EN MATERIA DE PRIVACIDAD?</h6>
  <p>
  Tienes derecho a::
  </p>
  <ul>
  <li>acceder a tus datos personales y a la información relacionada con los mismos;</li>
  <li>rectificar datos inexactos o proporcionar datos incompletos;</li>
  <li>eliminar tus datos personales (en caso de comprobarse algunas de las condiciones señaladas en el art. 17, párr. 1 del RGPD, y en cumplimiento de las excepciones prevista en el párr. 3 del mismo artículo), sin perjuicio de lo dispuesto en el punto 9 del presente documento informativo;</li>
  <li>cualquier posible limitación del tratamiento de tus datos personales;</li>
  <li>solicitar y obtener del Responsable del Tratamiento tus datos personales en un formato estructurado y legible mediante un dispositivo automático, incluso con el fin de proporcionar dichos datos a otro responsable del tratamiento;</li>
  <li>oponerte en cualquier momento al tratamiento de tus datos personales en caso de producirse situaciones particulares que te afecten;</li>
  <li>retirar el consentimiento al tratamiento;</li>
  <li>para ejercer estos derechos, puedes ponerte en contacto con el Responsable enviando un correo electrónico a la dirección learning.development@bracco.com o por correo ordinario a la siguiente dirección Bracco Imaging spa, via Folli, 50, 20134 Milán, c/a Human Resources – People Development.</li>
  <li>En cualquier caso, el interesado siempre puede presentar una queja ante el Garante para la Protección de Datos Personales (sitio web http://www.garanteprivacy.it/).</li>
  </ul>
`,
};
