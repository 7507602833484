export default {
  title: "Informativa sul trattamento dei dati personali",
  read1: "CONSENSO AI SENSI DELL’ART. 7 DEL REGOLAMENTO UE 2016/679",
  read2:
    "Il sottoscritto dichiara di aver compreso integralmente l’informativa fornita da Bracco quale Titolare del trattamento e dichiara di:",
  read3:
    "acconsentire al trattamento per la finalità di cui alla informativa, ivi compresa la profilazione",
  read4:
    "Nel caso in cui non dovessi prestare il consenso, non potrai utilizzare il servizio offerto dal Titolare.",
  text_nominale_footer: `
    <h6>1. COSA SI INTENDE PER TRATTAMENTO DEI DATI PERSONALI?</h6>
    <p>
    Si intende la raccolta, registrazione, organizzazione, strutturazione, conservazione, adattamento o modifica,
    estrazione, consultazione, uso, comunicazione, diffusione, raffronto o interconnessione, limitazione, cancellazione o
    distruzione dei medesimi dati.
    </p>
    <h6>2. CHI E’ Il TITOLARE DEI MIEI DATI PERSONALI?</h6>
    <p>
    Il Titolare del Trattamento è Bracco Imaging spa, con sede legale in Via Folli, 50 (MI).
    Il Responsabile della protezione dei dati (“DPO”) può essere contattato all’indirizzo e-mail <a href="mailto:dpo@bracco.com">dpo@bracco.com</a>. 
    </p>
    <h6>3. PER QUALI FINALITÀ SONO TRATTATI I MIEI DATI?</h6>
    <p>
    Per eseguire il servizio offerto dal Titolare: il trattamento dei Tuoi dati è finalizzato a permetterTi
    l’accesso ad esperienze di testing e video ludiche per mappare soft skill e restituire dati di performance 
    su specifiche competenze; per inviarTi comunicazioni inerenti il servizio medesimo; per adempiere agli obblighi 
    di legge ed ottemperare alle richieste provenienti dalle Autorità.
    </p>
    <h6>4. QUALI SONO LE BASI GIURIDICHE DEL TRATTAMENTO DEI MIEI DATI?</h6>
    <p>
    La base giuridica del trattamento è il consenso. Nel caso in cui non dovessi prestare il consenso, non potrai utilizzare 
    il servizio offerto dal Titolare. Hai il diritto di revocare in qualsiasi momento il consenso prestato. 
    La revoca del consenso non pregiudica la liceità del trattamento in base al consenso fornito prima della revoca.
    </p>
    <h6>5. QUALI DATI PERSONALI VENGONO TRATTATI?</h6>
    <p>
    I dati definiti “comuni”, quali ad esempio, dati anagrafici, email, il genere, l’anno di nascita. 
    Vengono trattati anche dati relativi alle abitudini e/o preferenze videoludiche, come specificato al successivo 
    paragrafo 8. Non vengono trattati dati inerenti i cookies.
    </p>
    <p>
    Non verranno trattati i dati di soggetti minori. Il Titolare del trattamento non fa utilizzo di cookies e pertanto 
    non verranno trattati dati provenienti dall’utilizzo degli stessi.
    </p>
    <h6>6. COSA SUCCEDE SE NON FORNISCO I MIEI DATI PERSONALI?</h6>
    <p>
    La mancata comunicazione dei Tuoi dati non Ti permetterà di utilizzare il servizio offerto dal Titolare.
    </p>
    <h6>7. A CHI VERRANNO COMUNICATI I MIEI DATI?</h6>
    <p>
    Nei limiti pertinenti alle finalità di trattamento di cui alla presente Informativa, i Tuoi dati potranno essere comunicati a:
    </p>
    <ul>
    <li>la Società, responsabile del trattamento, con la quale il Titolare ha stipulato il contratto di utilizzo della web-app;</li>
    <li>dipendenti o collaboratori del Titolare qualificati come “autorizzati”;</li>
    <li>autorità giudiziarie o amministrative, per l’adempimento degli obblighi di legge</li>
    <li>soggetti opportunamente designati “Responsabili del trattamento” dal Titolare del trattamento che eroghino prestazioni funzionali ai fini sopra indicati;</li>
    <li>soggetti che elaborano i dati in esecuzione di specifici obblighi di legge.</li>
    </ul>
    <p>I Tuoi dati NON saranno oggetto di diffusione.</p>
    <p>I Tuoi dati NON verranno trasferiti ad un paese non appartenente all’Unione Europea o ad un'organizzazione internazionale;</p>
    <h6>8. COME VENGONO TRATTATI I MIEI DATI? VENGONO PROFILATI?</h6>
    <p>
    Il trattamento dei Tuoi dati personali viene effettuato su supporto informatico (ivi compresi dispositivi portatili) 
    nel rispetto di quanto previsto dalla normativa vigente.
    </p>
    <p>
    I tuoi dati personali saranno soggetti a profilazione, solo previo Tuo espresso consenso. 
    La profilazione è ottenuta attraverso l’utilizzo da parte dell’Utente della applicazione PlayYourJob che - sulla base delle 
    Tue preferenze di gioco - è adatta a mappare le soft skill e/o a restituire dati di performance su specifiche competenze. 
    </p>
    <h6>9. DOVE E PER QUANTO TEMPO VERRANNO CONSERVATI I MIEI DATI?</h6>
    <p>
    I Tuoi dati verranno archiviati in “cloud”, su server siti all’interno dell’Unione Europea e verranno conservati per 
    il tempo necessario a conseguire le finalità indicate.
    </p>
    <p>
    Se sei un candidato i Tuoi dati saranno conservati per il periodo strettamente necessario a conseguire le finalità 
    per cui sono stati raccolti e in ogni caso per un periodo non superiore ad 3 anni. Decorso il suddetto termine, i Tuoi 
    dati saranno cancellati.
    </p>
    <p>
    Se sei un dipendente i Tuoi dati saranno conservati per tutta la durata del rapporto di lavoro ed anche successivamente per 
    l’espletamento di tutti gli adempimenti di legge. Decorso il suddetto termine, i Tuoi dati saranno cancellati.
    </p>
    <h6>10. QUALI SONO I MIEI DIRITTI PREVISTI DALLA NORMATIVA PRIVACY VIGENTE?</h6>
    <p>
    Hai diritto a:
    </p>
    <ul>
    <li>accedere ai Tuoi dati personali ed alle informazioni relative agli stessi;</li>
    <li>alla rettifica dei dati inesatti o l'integrazione di quelli incompleti;</li>
    <li>alla eventuale cancellazione dei Tuoi dati personali che Ti riguardano (al verificarsi di una delle condizioni indicate nell'art. 17, paragrafo 1, RGPD, e nel rispetto delle eccezioni previste nel paragrafo 3 dello stesso articolo), fermo restando quanto previsto al punto 9 della presente informativa;</li>
    <li>alla eventuale limitazione del trattamento dei Tuoi dati personali;</li>
    <li>richiedere ed ottenere dal Titolare del trattamento i Tuoi dati personali in un formato strutturato e leggibile da dispositivo automatico, anche al fine di comunicare tali dati ad un altro titolare del trattamento;</li>
    <li>opporti in qualsiasi momento al trattamento dei Tuoi dati personali al ricorrere di situazioni particolari che Ti riguardano;</li>
    <li>revocare il consenso al trattamento;</li>
    <li>per esercitare tali diritti, puoi contattare il Titolare mediante invio di un’e-mail all’indirizzo learning.development@bracco.com o tramite posta ordinaria al seguente indirizzo Bracco Imaging spa, via Folli, 50, 20134 Milano, c/a Human Resources – People Development.  </li>
    <li>In ogni caso l’interessato può sempre proporre reclamo al Garante per la Protezione dei Dati Personali (sito internet http://www.garanteprivacy.it/).</li>
    </ul>
`,
  text_nominale: `
    <h6>1. COSA SI INTENDE PER TRATTAMENTO DEI DATI PERSONALI?</h6>
    <p>
    Si intende la raccolta, registrazione, organizzazione, strutturazione, conservazione, adattamento o modifica,
    estrazione, consultazione, uso, comunicazione, diffusione, raffronto o interconnessione, limitazione, cancellazione o
    distruzione dei medesimi dati.
    </p>
    <h6>2. CHI E’ Il TITOLARE DEI MIEI DATI PERSONALI?</h6>
    <p>
    Il Titolare del Trattamento è Bracco Imaging spa, con sede legale in Via Folli, 50 (MI).
    Il Responsabile della protezione dei dati (“DPO”) può essere contattato all’indirizzo e-mail <a href="mailto:dpo@bracco.com">dpo@bracco.com</a>. 
    </p>
    <h6>3. PER QUALI FINALITÀ SONO TRATTATI I MIEI DATI?</h6>
    <p>
    Per eseguire il servizio offerto dal Titolare: il trattamento dei Tuoi dati è finalizzato a permetterTi
    l’accesso ad esperienze di testing e video ludiche per mappare soft skill e restituire dati di performance 
    su specifiche competenze; per inviarTi comunicazioni inerenti il servizio medesimo; per adempiere agli obblighi 
    di legge ed ottemperare alle richieste provenienti dalle Autorità.
    </p>
    <h6>4. QUALI SONO LE BASI GIURIDICHE DEL TRATTAMENTO DEI MIEI DATI?</h6>
    <p>
    La base giuridica del trattamento è il consenso. Nel caso in cui non dovessi prestare il consenso, non potrai utilizzare 
    il servizio offerto dal Titolare. Hai il diritto di revocare in qualsiasi momento il consenso prestato. 
    La revoca del consenso non pregiudica la liceità del trattamento in base al consenso fornito prima della revoca.
    </p>
    <h6>5. QUALI DATI PERSONALI VENGONO TRATTATI?</h6>
    <p>
    I dati definiti “comuni”, quali ad esempio, dati anagrafici, email, il genere, l’anno di nascita. 
    Vengono trattati anche dati relativi alle abitudini e/o preferenze videoludiche, come specificato al successivo 
    paragrafo 8. Non vengono trattati dati inerenti i cookies.
    </p>
    <p>
    Non verranno trattati i dati di soggetti minori. Il Titolare del trattamento non fa utilizzo di cookies e pertanto 
    non verranno trattati dati provenienti dall’utilizzo degli stessi.
    </p>
    <h6>6. COSA SUCCEDE SE NON FORNISCO I MIEI DATI PERSONALI?</h6>
    <p>
    La mancata comunicazione dei Tuoi dati non Ti permetterà di utilizzare il servizio offerto dal Titolare.
    </p>
    <h6>7. A CHI VERRANNO COMUNICATI I MIEI DATI?</h6>
    <p>
    Nei limiti pertinenti alle finalità di trattamento di cui alla presente Informativa, i Tuoi dati potranno essere comunicati a:
    </p>
    <ul>
    <li>la Società, responsabile del trattamento, con la quale il Titolare ha stipulato il contratto di utilizzo della web-app;</li>
    <li>dipendenti o collaboratori del Titolare qualificati come “autorizzati”;</li>
    <li>autorità giudiziarie o amministrative, per l’adempimento degli obblighi di legge</li>
    <li>soggetti opportunamente designati “Responsabili del trattamento” dal Titolare del trattamento che eroghino prestazioni funzionali ai fini sopra indicati;</li>
    <li>soggetti che elaborano i dati in esecuzione di specifici obblighi di legge.</li>
    </ul>
    <p>I Tuoi dati NON saranno oggetto di diffusione.</p>
    <p>I Tuoi dati NON verranno trasferiti ad un paese non appartenente all’Unione Europea o ad un'organizzazione internazionale;</p>
    <h6>8. COME VENGONO TRATTATI I MIEI DATI? VENGONO PROFILATI?</h6>
    <p>
    Il trattamento dei Tuoi dati personali viene effettuato su supporto informatico (ivi compresi dispositivi portatili) 
    nel rispetto di quanto previsto dalla normativa vigente.
    </p>
    <p>
    I tuoi dati personali saranno soggetti a profilazione, solo previo Tuo espresso consenso. 
    La profilazione è ottenuta attraverso l’utilizzo da parte dell’Utente della applicazione PlayYourJob che - sulla base delle 
    Tue preferenze di gioco - è adatta a mappare le soft skill e/o a restituire dati di performance su specifiche competenze. 
    </p>
    <h6>9. DOVE E PER QUANTO TEMPO VERRANNO CONSERVATI I MIEI DATI?</h6>
    <p>
    I Tuoi dati verranno archiviati in “cloud”, su server siti all’interno dell’Unione Europea e verranno conservati per 
    il tempo necessario a conseguire le finalità indicate.
    </p>
    <p>
    Se sei un candidato i Tuoi dati saranno conservati per il periodo strettamente necessario a conseguire le finalità 
    per cui sono stati raccolti e in ogni caso per un periodo non superiore ad 3 anni. Decorso il suddetto termine, i Tuoi 
    dati saranno cancellati.
    </p>
    <p>
    Se sei un dipendente i Tuoi dati saranno conservati per tutta la durata del rapporto di lavoro ed anche successivamente per 
    l’espletamento di tutti gli adempimenti di legge. Decorso il suddetto termine, i Tuoi dati saranno cancellati.
    </p>
    <h6>10. QUALI SONO I MIEI DIRITTI PREVISTI DALLA NORMATIVA PRIVACY VIGENTE?</h6>
    <p>
    Hai diritto a:
    </p>
    <ul>
    <li>accedere ai Tuoi dati personali ed alle informazioni relative agli stessi;</li>
    <li>alla rettifica dei dati inesatti o l'integrazione di quelli incompleti;</li>
    <li>alla eventuale cancellazione dei Tuoi dati personali che Ti riguardano (al verificarsi di una delle condizioni indicate nell'art. 17, paragrafo 1, RGPD, e nel rispetto delle eccezioni previste nel paragrafo 3 dello stesso articolo), fermo restando quanto previsto al punto 9 della presente informativa;</li>
    <li>alla eventuale limitazione del trattamento dei Tuoi dati personali;</li>
    <li>richiedere ed ottenere dal Titolare del trattamento i Tuoi dati personali in un formato strutturato e leggibile da dispositivo automatico, anche al fine di comunicare tali dati ad un altro titolare del trattamento;</li>
    <li>opporti in qualsiasi momento al trattamento dei Tuoi dati personali al ricorrere di situazioni particolari che Ti riguardano;</li>
    <li>revocare il consenso al trattamento;</li>
    <li>per esercitare tali diritti, puoi contattare il Titolare mediante invio di un’e-mail all’indirizzo learning.development@bracco.com o tramite posta ordinaria al seguente indirizzo Bracco Imaging spa, via Folli, 50, 20134 Milano, c/a Human Resources – People Development.  </li>
    <li>In ogni caso l’interessato può sempre proporre reclamo al Garante per la Protezione dei Dati Personali (sito internet http://www.garanteprivacy.it/).</li>
    </ul>
`,
  text_anonimo: `
    <h6>1. COSA SI INTENDE PER TRATTAMENTO DEI DATI PERSONALI?</h6>
    <p>
    Si intende la raccolta, registrazione, organizzazione, strutturazione, conservazione, adattamento o modifica,
    estrazione, consultazione, uso, comunicazione, diffusione, raffronto o interconnessione, limitazione, cancellazione o
    distruzione dei medesimi dati.
    </p>
    <h6>2. CHI E’ Il TITOLARE DEI MIEI DATI PERSONALI?</h6>
    <p>
    Il Titolare del Trattamento è Bracco Imaging spa, con sede legale in Via Folli, 50 (MI).
    Il Responsabile della protezione dei dati (“DPO”) può essere contattato all’indirizzo e-mail <a href="mailto:dpo@bracco.com">dpo@bracco.com</a>. 
    </p>
    <h6>3. PER QUALI FINALITÀ SONO TRATTATI I MIEI DATI?</h6>
    <p>
    Per eseguire il servizio offerto dal Titolare: il trattamento dei Tuoi dati è finalizzato a permetterTi
    l’accesso ad esperienze di testing e video ludiche per mappare soft skill e restituire dati di performance 
    su specifiche competenze; per inviarTi comunicazioni inerenti il servizio medesimo; per adempiere agli obblighi 
    di legge ed ottemperare alle richieste provenienti dalle Autorità.
    </p>
    <h6>4. QUALI SONO LE BASI GIURIDICHE DEL TRATTAMENTO DEI MIEI DATI?</h6>
    <p>
    La base giuridica del trattamento è il consenso. Nel caso in cui non dovessi prestare il consenso, non potrai utilizzare 
    il servizio offerto dal Titolare. Hai il diritto di revocare in qualsiasi momento il consenso prestato. 
    La revoca del consenso non pregiudica la liceità del trattamento in base al consenso fornito prima della revoca.
    </p>
    <h6>5. QUALI DATI PERSONALI VENGONO TRATTATI?</h6>
    <p>
    I dati definiti “comuni”, quali ad esempio, dati anagrafici, email, il genere, l’anno di nascita. 
    Vengono trattati anche dati relativi alle abitudini e/o preferenze videoludiche, come specificato al successivo 
    paragrafo 8. Non vengono trattati dati inerenti i cookies.
    </p>
    <p>
    Non verranno trattati i dati di soggetti minori. Il Titolare del trattamento non fa utilizzo di cookies e pertanto 
    non verranno trattati dati provenienti dall’utilizzo degli stessi.
    </p>
    <h6>6. COSA SUCCEDE SE NON FORNISCO I MIEI DATI PERSONALI?</h6>
    <p>
    La mancata comunicazione dei Tuoi dati non Ti permetterà di utilizzare il servizio offerto dal Titolare.
    </p>
    <h6>7. A CHI VERRANNO COMUNICATI I MIEI DATI?</h6>
    <p>
    Nei limiti pertinenti alle finalità di trattamento di cui alla presente Informativa, i Tuoi dati potranno essere comunicati a:
    </p>
    <ul>
    <li>la Società, responsabile del trattamento, con la quale il Titolare ha stipulato il contratto di utilizzo della web-app;</li>
    <li>dipendenti o collaboratori del Titolare qualificati come “autorizzati”;</li>
    <li>autorità giudiziarie o amministrative, per l’adempimento degli obblighi di legge</li>
    <li>soggetti opportunamente designati “Responsabili del trattamento” dal Titolare del trattamento che eroghino prestazioni funzionali ai fini sopra indicati;</li>
    <li>soggetti che elaborano i dati in esecuzione di specifici obblighi di legge.</li>
    </ul>
    <p>I Tuoi dati NON saranno oggetto di diffusione.</p>
    <p>I Tuoi dati NON verranno trasferiti ad un paese non appartenente all’Unione Europea o ad un'organizzazione internazionale;</p>
    <h6>8. COME VENGONO TRATTATI I MIEI DATI? VENGONO PROFILATI?</h6>
    <p>
    Il trattamento dei Tuoi dati personali viene effettuato su supporto informatico (ivi compresi dispositivi portatili) 
    nel rispetto di quanto previsto dalla normativa vigente.
    </p>
    <p>
    I tuoi dati personali saranno soggetti a profilazione, solo previo Tuo espresso consenso. 
    La profilazione è ottenuta attraverso l’utilizzo da parte dell’Utente della applicazione PlayYourJob che - sulla base delle 
    Tue preferenze di gioco - è adatta a mappare le soft skill e/o a restituire dati di performance su specifiche competenze. 
    </p>
    <h6>9. DOVE E PER QUANTO TEMPO VERRANNO CONSERVATI I MIEI DATI?</h6>
    <p>
    I Tuoi dati verranno archiviati in “cloud”, su server siti all’interno dell’Unione Europea e verranno conservati per 
    il tempo necessario a conseguire le finalità indicate.
    </p>
    <p>
    Se sei un candidato i Tuoi dati saranno conservati per il periodo strettamente necessario a conseguire le finalità 
    per cui sono stati raccolti e in ogni caso per un periodo non superiore ad 3 anni. Decorso il suddetto termine, i Tuoi 
    dati saranno cancellati.
    </p>
    <p>
    Se sei un dipendente i Tuoi dati saranno conservati per tutta la durata del rapporto di lavoro ed anche successivamente per 
    l’espletamento di tutti gli adempimenti di legge. Decorso il suddetto termine, i Tuoi dati saranno cancellati.
    </p>
    <h6>10. QUALI SONO I MIEI DIRITTI PREVISTI DALLA NORMATIVA PRIVACY VIGENTE?</h6>
    <p>
    Hai diritto a:
    </p>
    <ul>
    <li>accedere ai Tuoi dati personali ed alle informazioni relative agli stessi;</li>
    <li>alla rettifica dei dati inesatti o l'integrazione di quelli incompleti;</li>
    <li>alla eventuale cancellazione dei Tuoi dati personali che Ti riguardano (al verificarsi di una delle condizioni indicate nell'art. 17, paragrafo 1, RGPD, e nel rispetto delle eccezioni previste nel paragrafo 3 dello stesso articolo), fermo restando quanto previsto al punto 9 della presente informativa;</li>
    <li>alla eventuale limitazione del trattamento dei Tuoi dati personali;</li>
    <li>richiedere ed ottenere dal Titolare del trattamento i Tuoi dati personali in un formato strutturato e leggibile da dispositivo automatico, anche al fine di comunicare tali dati ad un altro titolare del trattamento;</li>
    <li>opporti in qualsiasi momento al trattamento dei Tuoi dati personali al ricorrere di situazioni particolari che Ti riguardano;</li>
    <li>revocare il consenso al trattamento;</li>
    <li>per esercitare tali diritti, puoi contattare il Titolare mediante invio di un’e-mail all’indirizzo learning.development@bracco.com o tramite posta ordinaria al seguente indirizzo Bracco Imaging spa, via Folli, 50, 20134 Milano, c/a Human Resources – People Development.  </li>
    <li>In ogni caso l’interessato può sempre proporre reclamo al Garante per la Protezione dei Dati Personali (sito internet http://www.garanteprivacy.it/).</li>
    </ul>
`,
};
