import React, { useEffect, useCallback, useMemo, useRef } from "react";
import { Link, Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { updateProgress } from "../utils/utils.js";
import { Container } from "./App";
import { useGetLanguage } from "./components/LanguageSelector";

import "../css/pyt.scss";
import { useNamespacedTranslation } from "./components/LanguageSelector";

// PlayYourTest questions
export default function PYT() {
  const { t } = useNamespacedTranslation();
  return (
    <Routes>
      <Route path="/*" element={<WelcomePYT />} />
      <Route path="question/:n/*" element={<Question />} />
      <Route
        path="question/error/*"
        element={
          <h4>
            {t("pyt.error")}
            <Link to="../question/0">{t("pyt.retry")}</Link>{" "}
          </h4>
        }
      />
    </Routes>
  );
}

// Welcome PYT
const WelcomePYT = () => {
  const { t } = useNamespacedTranslation();
  const goTo = useNavigate();
  const start = useCallback(() => goTo("question/2"), [goTo]);
  return (
    <Container>
      <h1>PlayYourTest</h1>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: t("pyt.description") }}
      />
      <Button variant="primary" size="lg" onClick={start}>
        {t("pyt.start")}
      </Button>
    </Container>
  );
};

// Question :n
function Question() {
  // get number of question
  const { t } = useNamespacedTranslation();
  const language = useGetLanguage();
  const { n } = useParams();
  const nInt = useMemo(() => parseInt(n), [n]);
  const goTo = useNavigate();
  // get features
  const features = useSelector((state) =>
    state.userData.map((f) =>
      Object.assign(new String(f.path), { vincolato: !!parseInt(f.vincolato) })
    )
  );
  // use this progress from server to
  const progressBeforeReplay = useSelector(
    (state) => state.progressBeforeReplay
  );
  // get info from store
  const questionary = useSelector((state) => {
    const featureData = JSON.parse(
      state.userData.find((f) => f.path.includes("test")).feature_json
    );
    // se esiste una versione testi, usala come suffisso dopo "_" per prendere la chiave del json
    let selezioneTesti = language;
    const versione_testi = state.userData?.[0]?.versione_testi;
    if(versione_testi) selezioneTesti += "_" + versione_testi;

    return featureData[selezioneTesti] ?? featureData;
  });
  // check if it answered, if yes go to next, blur button and save state
  const goNext = useCallback(
    (e) => {
      e.persist();
      e.target.blur();
      e.target.disabled = true;
      // don't join queries or choice could be input
      const [choice, input] = [
        document.querySelector(".labels :checked"),
        document.querySelector(".question input[type=text]"),
      ];
      if (
        choice &&
        (!input ||
          (/^[12][90][0-9][0-9]$/.test(input.value) &&
            parseInt(input.value) > new Date().getFullYear() - 120 &&
            parseInt(input.value) < new Date().getFullYear() - 2))
      ) {
        // if there is another question, save it and go to the next
        // else save to profile and go to it
        const q = input ? [`q${n}`, `q${n}_extra`] : [`q${n}`];
        const a = input ? [choice.value, input.value] : [choice.value];
        const testIndex = features.findIndex((f) => f.includes("test"));
        const nextFeature =
          features
            .filter((f, i) => i < testIndex && f.vincolato)
            .slice(-1)[0] || "home";
        const pr = questionary.questions[nInt + 1]
          ? `pyt/question/${nInt + 1}`
          : progressBeforeReplay || "profile/" + nextFeature;
        const go = questionary.questions[nInt + 1]
          ? "../" + (nInt + 1)
          : progressBeforeReplay
          ? "/user/" + progressBeforeReplay
          : "/user/profile/" + nextFeature;
        // wait for progress to be saved before going forward
        updateProgress(pr, "playyourtest", q, a).then(() => {
          choice.checked = false;
          e.target.disabled = false;
          goTo(go);
        });
      } else {
        e.target.disabled = false;
        goTo("alert_valid");
      }
    },
    [questionary, goTo, n, nInt, features, progressBeforeReplay]
  );
  // memoize choices and extra
  const [answers, extra] = useMemo(
    () =>
      questionary.questions && questionary.questions[nInt]
        ? [
            // answers
            (questionary.questions[nInt].a || questionary.defaults.a).map(
              (a, i) => (
                <label key={a}>
                  <input type="radio" name="answer" value={i} />
                  <span>{a}</span>
                </label>
              )
            ),
            // extra
            questionary.questions[nInt].e && (
              <>
                <h2>{questionary.questions[nInt].e[0]}</h2>
                <input
                  type="text"
                  placeholder={questionary.questions[nInt].e[1]}
                />
              </>
            ),
          ]
        : ["", ""],
    [questionary, nInt]
  );
  // return component with values, only if there is a question with that number loaded
  return !questionary.questions || !questionary.questions[nInt] ? (
    <div></div>
  ) : (
    <Container>
      <div
        className="appDescription"
        style={{ textAlign: "center", padding: "15px 10px 0 10px" }}
        dangerouslySetInnerHTML={{ __html: t("pyt.guide") }}
      />
      <div className="question">
        <h1>{questionary.questions[nInt].t || questionary.defaults.t}</h1>
        <div className="question innerbox">
          <h2>{questionary.questions[nInt].q}</h2>
          <div className="labels">{answers}</div>
          {extra}
          <Button variant="primary" size="lg" onClick={goNext}>
            {t("pyt.confirm")}
          </Button>
        </div>
      </div>
    </Container>
  );
}

// PYT Progress Bar
export function ProgressBar() {
  // get number of question, length and percentage
  const { n } = useParams(); // use * because the param is defined as * in header; ignore the warning
  const language = useGetLanguage();
  const nQuestions = useSelector((state) =>
    !state?.userData?.find
      ? 1000
      : (() => {
          const data = JSON.parse(
            state.userData?.find((f) => f.path.includes("test"))?.feature_json
          );
          return data[language] ?? data;
        })().questions.length || 1000
  ); // try getting length or use big number
  const progress = useMemo(
    () => Math.ceil((100 * parseInt(n)) / nQuestions),
    [n, nQuestions]
  );
  // get ref and modify css prop
  const bar = useRef();
  useEffect(() => {
    bar.current.style.setProperty("--progress", progress + "%");
  }, [bar, progress]);
  // render
  return (
    <div ref={bar} id="pytProgress">
      {progress}%
    </div>
  );
}
