import terms from "./terms/en";
import areas from "./areas/en";

export default {
  translation: {
    login: {
      title: "Log in",
      insert: "Enter the 10-digit alphanumeric code that was provided to you",
      or: "or",
      qr: "Scan the QR code",
      qr_back: "Go back",
      exit: "Exit",
      retry: "Try again",
      failed_1: "Authentication failed.",
      failed_2:
        "Make sure you have typed the token correctly, respecting upper and lower case.",
      failed_active:
        "Your token is not active yet. It will be effective from the following date:",
      failed_expired: "Your token is no longer valid.",
    },
    footer: {
      help: "Help! My code or QR is not working",
    },
    overlay: {
      close: "CLOSE",
      undo: "CANCEL",
      help: "Do you need help? Write an email to:",
      alert_valid: "Please choose a valid option before continuing!",
      alert_terms:
        "Before proceeding, you must fill in your personal details and accept the terms and conditions! Also check that you have entered the information correctly.",
      confirm_vincolato:
        "You are about to start the game. Once you click OK, you will have 20 minutes to get the best score. Make sure you have carefully read and understood all the instructions. Also make sure you have time, a stable connection, and a comfortable, quiet location. We suggest that you start the game from a PC with a mouse for the best playing conditions.",
      confirm_libero: "Do you really want to exit the game?",
      receive_mail:
        "Enter your email to receive the detailed profile in pdf format.",
      receive_mail_placeholder: "Write your email here",
      mail_success: "Email sent successfully!",
      mail_error: "Error while sending the email.",
      alert_logout_1:
        " Warning! Upon exiting, you will no longer be able to recover your progress.",
      alert_logout_2: "If you want to re-enter later, keep this link: ",
    },
    user: {
      welcome: "Welcome",
      app_description_1: `
        <b>PlayYourJob</b> is a project aimed at reducing the distance between 
        work supply and demand, developed and validated by <b>Laborplay</b>, an 
        approved spin-off of the <b>University of Florence</b>. By participating 
        in the project, we will give you the profile of some of your 
        professional characteristics.
      `,
      app_description_2: `
        The <b>Performance</b> activities must be carried out in a maximum time limit. Make sure you have enough time to give it your all.
        <br />
        <b>Training</b> activities do not have a maximum time limit, and you can 
        access them whenever you want.
      `,
      active_title: "Active packages",
      loading: "LOADING...",
      confirm: "CONFIRM AND START",
      anagraphics: {
        title: "Personal details",
        description:
          "Fill in the following fields to complete the tasks and save your results.",
        name: "Name",
        name_placeholder: "Please enter your name here",
        surname: "Surname",
        surname_placeholder: "Type your surname here",
        cf: "Social Security Number",
        cf_placeholder: "Enter your Social Security Number here",
        email_placeholder: "Enter your e-mail here",
        year: "Year of birth",
        gender: "Gender",
        male: "Male",
        female: "Female",
        other: "Other",
        frequency: "How long have you been working?",
        never: "Less than 2 years",
        monthly: "2 to 5 years",
        weekly: "6 to 15 years",
        daily: "16 to 25 years",
        hourly: "More than 25 years",
      },
    },
    games: {
      open: "Open",
      results: "Results",
      title: "Put your skills to the test",
      title_libero: "Free Play",
      close: "Close",
      retry: "Try Again",
      game_order:
        "Before you can play this game, you have to play the previous ones",
      before_rank: "Better than ",
      after_rank: " the players",
      test: "Go to the Test",
    },
    profile: {
      repeat: "Repeat test",
      own: "Your PLAY profile is: ",
      download: "Download your profile",
      title: "List of soft skills characteristic of this area",
      description:
        "Scores equal to or greater than 4 represent the gradually increasing presence of the following skills",
      areas,
    },
    pyt: {
      error: "Error retrieving the questions. ",
      retry: "Try again",
      description: `
        <p>
          The Play Your Test is an original questionnaire developed by Laborplay and validated on a sample of over 25,000 people. 
          The questions are designed to understand your work habits and preferences; 
          your answers allow for the creation of a profile of your transversal skills, 
          which will help you reflect on your strengths and areas for improvement.
        </p>
        <p>
          The Play Your Test is not just for those who have been working for a long time.
        </p>
        <h4 class="superTitle" className="superTitle">Play Your Test is designed for everyone.</h4>
        <h4>Because we all know what we are passionate about.</h4>
        <p>
          Answer thinking about your preferences and if you've never thought about it before, 
          now is the right time! Then indicate the degree of agreement with the following statements.
        </p>
      `,
      start: "START THE TEST",
      guide: `
        <p>
         It is not important whether you are currently working or not, we just ask you to reflect on your preferences (even potential ones) and indicate the degree of agreement with the following statements. Using the scale below, please indicate how much you like the following aspects of a job. 
        <br />
        <b>WHAT DO YOU LIKE (OR WOULD LIKE) IN A JOB?</b>
        <br />
        <i>1 = totally agree, 2 = partially agree, 3 = partially disagree, 4 = totally disagree</i>
        </p>
      `,
      confirm: "Confirm",
    },
    profiles: {
      Analitico: "Analytical",
      Attivatore: "Motivating",
      Audace: "Bold",
      Brillante: "Brilliant",
      Carismatico: "Charismatic",
      Cauto: "Cautious",
      Collaborativo: "Collaborative",
      Determinato: "Determined",
      Disponibile: "Available",
      Equilibrato: "Balanced",
      Influente: "Influencer",
      Innovatore: "Innovative",
      Negoziatore: "Negotiator",
      Riflessivo: "Reflective",
      Sistematico: "Systematic",
      Socievole: "Sociable",
      Vivace: " Lively",
    },
    terms,
  },
};
