export default {
  title: "Information on the processing of personal data",
  read1: "CONSENT PURSUANT TO ART. 7 OF THE 2016/679 EU REGULATION",
  read2:
    "The undersigned declares to have fully understood the information provided by Bracco as Data Controller and declares to",
  read3:
    "consent to the processing for the purposes referred to in the information, including profiling",
  read4:
    "In the event that you do not have to give your consent, you will not be able to use the service offered by the Data Controller.",
  text_nominale_footer: `
  <h6> 1. WHAT IS MEANT BY THE PROCESSING OF PERSONAL DATA? </h6>
  <p>
  It means the collection, registration, organisation, structuring, conservation, adaptation or modification, extraction, consultation, use, communication, disclosure, comparison or interconnection, limitation, deletion or destruction of the data.
  </p>
  <h6> 2. WHO IS THE OWNER OF MY PERSONAL DATA? </h6>
  <p>
  The Data Controller is Bracco Imaging spa, with registered office in Via Folli, 50 (MI).
  The Data Protection Officer ("DPO") can be contacted at the email address <a href="mailto:dpo@bracco.com"> dpo@bracco.com </a>.
  </p>
  <h6> 3. FOR WHAT PURPOSES ARE MY DATA PROCESSED? </h6>
  <p>
  To perform the service offered by the Data Controller: the processing of your data is aimed at allowing you access to testing experiences and video games to map soft skills and return performance data on specific skills; to send you communications relating to the service itself; to comply with legal obligations and comply with requests from the Authorities.
  </p>
  <h6> 4. WHAT IS THE LEGAL BASIS FOR THE PROCESSING OF MY DATA? </h6>
  <p>
  The legal basis for the processing is consent. In the event that you do not give your consent, you will not be able to use the service offered by the Data Controller. You have the right to withdraw your consent at any time. Withdrawal of consent shall not affect the lawfulness of the processing based on your consent given prior to the revocation.
  </p>
  <h6> 5. WHICH PERSONAL DATA ARE PROCESSED? </h6>
  <p>
  Data defined as "common", such as, for example, personal data, email, gender, year of birth. Data relating to gaming habits and/or preferences are also processed, as specified in paragraph 8 below. Data relating to cookies are not processed.
  </p>
  <p>
  The data of minors will not be processed. The Data Controller does not use cookies and therefore data from their use will not be processed.
  </p>
  <h6> 6. WHAT HAPPENS IF I DON'T PROVIDE MY PERSONAL DATA? </h6>
  <p>
  Failure to communicate your data will prevent you from using the service offered by the Controller.
  </p>
  <h6> 7. TO WHOM WILL MY DATA BE COMMUNICATED?</h6>
  <p>
  To the extent relevant to the processing purposes referred to in this Policy, your data may be communicated to:
  </p>
  <ul>
  <li>the Company, responsible for the processing, with which the Data Controller has entered into the contract for the use of the web-app;</li>
  <li>employees or partners of the Data Controller qualified as "authorised";</li>
  <li>judicial or administrative authorities to fulfil legal obligations;</li>
  <li>subjects appropriately designated "Data Processing Managers" by the Data Controller or who provide functional services for the purposes indicated above;</li>
  <li>subjects that process data in execution of specific legal obligations;</li>
  </ul>
  <p> Your data will NOT be disclosed. </p>
  <p> Your data will NOT be transferred to a country not belonging to the European Union or to an international organisation; </p>
  <h6> 8. HOW ARE MY DATA PROCESSED? ARE THEY PROFILED? </h6>
  <p>
  Your data will NOT be transferred to a country not belonging to the European Union or to an international organisation;
  </p>
  <p>
  Your personal data will only be subject to profiling if you have given your express consent. Profiling is obtained through the use by the User of the PlayYourJob application which - based on your game preferences - is suitable for mapping soft skills and/or returning performance data on specific skills.
  </p>
  <h6> 9. WHERE AND FOR HOW LONG WILL MY DATA BE STORED? </h6>
  <p>
  Your data will be stored in the "cloud", on servers located within the European Union, and will be kept for the time necessary to achieve the purposes indicated.
  </p>
  <p>
  If you are a candidate, your data will be kept for the period strictly necessary to achieve the purposes for which they were collected and in any case for a period not exceeding 3 years. After the aforementioned term, your personal data will be deleted.
  </p>
  <p>
  If you are an employee, your data will be stored for the duration of the employment relationship and also thereafter for the fulfilment of all legal obligations. After the aforementioned term, your personal data will be deleted.
  </p>
  <h6> 10. WHAT ARE MY RIGHTS UNDER CURRENT PRIVACY LEGISLATION?</h6>
  <p>
  You are entitled to:
  </p>
  <ul>
  <li>access your personal data and the information related thereto;</li>
  <li>correct inaccurate data or complete incomplete data;</li>
  <li>possibly delete your personal data (upon the occurrence of one of the conditions indicated in art.17, paragraph 1, GDPR, and in compliance with the exceptions provided for in paragraph 3 of the same article), without prejudice to the provisions of item 9 of this information document;</li>
  <li>possibly limit the processing of your personal data;</li>
  <li>request and obtain from the Data Controller your personal data in a structured format that can be read by an automated device, also in order to communicate these data to another data controller;</li>
  <li>object at any time to the processing of your personal data in the event of particular situations concerning you;</li>
  <li>withdraw consent to the processing;</li>
  <li>to exercise these rights, you can contact the Data Controller by sending an email to learning.development@bracco.com or by ordinary post to the following address: Bracco Imaging spa, via Folli, 50, 20134 Milan, c/a Human Resources – People Development.</li>
  <li>In any case, the interested party can always lodge a complaint with the Guarantor for the Protection of Personal Data (website http://www.garanteprivacy.it/).</li>
  </ul>
`,
  text_nominale: `
  <h6> 1. WHAT IS MEANT BY THE PROCESSING OF PERSONAL DATA? </h6>
  <p>
  It means the collection, registration, organisation, structuring, conservation, adaptation or modification, extraction, consultation, use, communication, disclosure, comparison or interconnection, limitation, deletion or destruction of the data.
  </p>
  <h6> 2. WHO IS THE OWNER OF MY PERSONAL DATA? </h6>
  <p>
  The Data Controller is Bracco Imaging spa, with registered office in Via Folli, 50 (MI).
  The Data Protection Officer ("DPO") can be contacted at the email address <a href="mailto:dpo@bracco.com"> dpo@bracco.com </a>.
  </p>
  <h6> 3. FOR WHAT PURPOSES ARE MY DATA PROCESSED? </h6>
  <p>
  To perform the service offered by the Data Controller: the processing of your data is aimed at allowing you access to testing experiences and video games to map soft skills and return performance data on specific skills; to send you communications relating to the service itself; to comply with legal obligations and comply with requests from the Authorities.
  </p>
  <h6> 4. WHAT IS THE LEGAL BASIS FOR THE PROCESSING OF MY DATA? </h6>
  <p>
  The legal basis for the processing is consent. In the event that you do not give your consent, you will not be able to use the service offered by the Data Controller. You have the right to withdraw your consent at any time. Withdrawal of consent shall not affect the lawfulness of the processing based on your consent given prior to the revocation.
  </p>
  <h6> 5. WHICH PERSONAL DATA ARE PROCESSED? </h6>
  <p>
  Data defined as "common", such as, for example, personal data, email, gender, year of birth. Data relating to gaming habits and/or preferences are also processed, as specified in paragraph 8 below. Data relating to cookies are not processed.
  </p>
  <p>
  The data of minors will not be processed. The Data Controller does not use cookies and therefore data from their use will not be processed.
  </p>
  <h6> 6. WHAT HAPPENS IF I DON'T PROVIDE MY PERSONAL DATA? </h6>
  <p>
  Failure to communicate your data will prevent you from using the service offered by the Controller.
  </p>
  <h6> 7. TO WHOM WILL MY DATA BE COMMUNICATED?</h6>
  <p>
  To the extent relevant to the processing purposes referred to in this Policy, your data may be communicated to:
  </p>
  <ul>
  <li>the Company, responsible for the processing, with which the Data Controller has entered into the contract for the use of the web-app;</li>
  <li>employees or partners of the Data Controller qualified as "authorised";</li>
  <li>judicial or administrative authorities to fulfil legal obligations;</li>
  <li>subjects appropriately designated "Data Processing Managers" by the Data Controller or who provide functional services for the purposes indicated above;</li>
  <li>subjects that process data in execution of specific legal obligations;</li>
  </ul>
  <p> Your data will NOT be disclosed. </p>
  <p> Your data will NOT be transferred to a country not belonging to the European Union or to an international organisation; </p>
  <h6> 8. HOW ARE MY DATA PROCESSED? ARE THEY PROFILED? </h6>
  <p>
  Your data will NOT be transferred to a country not belonging to the European Union or to an international organisation;
  </p>
  <p>
  Your personal data will only be subject to profiling if you have given your express consent. Profiling is obtained through the use by the User of the PlayYourJob application which - based on your game preferences - is suitable for mapping soft skills and/or returning performance data on specific skills.
  </p>
  <h6> 9. WHERE AND FOR HOW LONG WILL MY DATA BE STORED? </h6>
  <p>
  Your data will be stored in the "cloud", on servers located within the European Union, and will be kept for the time necessary to achieve the purposes indicated.
  </p>
  <p>
  If you are a candidate, your data will be kept for the period strictly necessary to achieve the purposes for which they were collected and in any case for a period not exceeding 3 years. After the aforementioned term, your personal data will be deleted.
  </p>
  <p>
  If you are an employee, your data will be stored for the duration of the employment relationship and also thereafter for the fulfilment of all legal obligations. After the aforementioned term, your personal data will be deleted.
  </p>
  <h6> 10. WHAT ARE MY RIGHTS UNDER CURRENT PRIVACY LEGISLATION?</h6>
  <p>
  You are entitled to:
  </p>
  <ul>
  <li>access your personal data and the information related thereto;</li>
  <li>correct inaccurate data or complete incomplete data;</li>
  <li>possibly delete your personal data (upon the occurrence of one of the conditions indicated in art.17, paragraph 1, GDPR, and in compliance with the exceptions provided for in paragraph 3 of the same article), without prejudice to the provisions of item 9 of this information document;</li>
  <li>possibly limit the processing of your personal data;</li>
  <li>request and obtain from the Data Controller your personal data in a structured format that can be read by an automated device, also in order to communicate these data to another data controller;</li>
  <li>object at any time to the processing of your personal data in the event of particular situations concerning you;</li>
  <li>withdraw consent to the processing;</li>
  <li>to exercise these rights, you can contact the Data Controller by sending an email to learning.development@bracco.com or by ordinary post to the following address: Bracco Imaging spa, via Folli, 50, 20134 Milan, c/a Human Resources – People Development.</li>
  <li>In any case, the interested party can always lodge a complaint with the Guarantor for the Protection of Personal Data (website http://www.garanteprivacy.it/).</li>
  </ul>
`,
  text_anonimo: `
  <h6> 1. WHAT IS MEANT BY THE PROCESSING OF PERSONAL DATA? </h6>
  <p>
  It means the collection, registration, organisation, structuring, conservation, adaptation or modification, extraction, consultation, use, communication, disclosure, comparison or interconnection, limitation, deletion or destruction of the data.
  </p>
  <h6> 2. WHO IS THE OWNER OF MY PERSONAL DATA? </h6>
  <p>
  The Data Controller is Bracco Imaging spa, with registered office in Via Folli, 50 (MI).
  The Data Protection Officer ("DPO") can be contacted at the email address <a href="mailto:dpo@bracco.com"> dpo@bracco.com </a>.
  </p>
  <h6> 3. FOR WHAT PURPOSES ARE MY DATA PROCESSED? </h6>
  <p>
  To perform the service offered by the Data Controller: the processing of your data is aimed at allowing you access to testing experiences and video games to map soft skills and return performance data on specific skills; to send you communications relating to the service itself; to comply with legal obligations and comply with requests from the Authorities.
  </p>
  <h6> 4. WHAT IS THE LEGAL BASIS FOR THE PROCESSING OF MY DATA? </h6>
  <p>
  The legal basis for the processing is consent. In the event that you do not give your consent, you will not be able to use the service offered by the Data Controller. You have the right to withdraw your consent at any time. Withdrawal of consent shall not affect the lawfulness of the processing based on your consent given prior to the revocation.
  </p>
  <h6> 5. WHICH PERSONAL DATA ARE PROCESSED? </h6>
  <p>
  Data defined as "common", such as, for example, personal data, email, gender, year of birth. Data relating to gaming habits and/or preferences are also processed, as specified in paragraph 8 below. Data relating to cookies are not processed.
  </p>
  <p>
  The data of minors will not be processed. The Data Controller does not use cookies and therefore data from their use will not be processed.
  </p>
  <h6> 6. WHAT HAPPENS IF I DON'T PROVIDE MY PERSONAL DATA? </h6>
  <p>
  Failure to communicate your data will prevent you from using the service offered by the Controller.
  </p>
  <h6> 7. TO WHOM WILL MY DATA BE COMMUNICATED?</h6>
  <p>
  To the extent relevant to the processing purposes referred to in this Policy, your data may be communicated to:
  </p>
  <ul>
  <li>the Company, responsible for the processing, with which the Data Controller has entered into the contract for the use of the web-app;</li>
  <li>employees or partners of the Data Controller qualified as "authorised";</li>
  <li>judicial or administrative authorities to fulfil legal obligations;</li>
  <li>subjects appropriately designated "Data Processing Managers" by the Data Controller or who provide functional services for the purposes indicated above;</li>
  <li>subjects that process data in execution of specific legal obligations;</li>
  </ul>
  <p> Your data will NOT be disclosed. </p>
  <p> Your data will NOT be transferred to a country not belonging to the European Union or to an international organisation; </p>
  <h6> 8. HOW ARE MY DATA PROCESSED? ARE THEY PROFILED? </h6>
  <p>
  Your data will NOT be transferred to a country not belonging to the European Union or to an international organisation;
  </p>
  <p>
  Your personal data will only be subject to profiling if you have given your express consent. Profiling is obtained through the use by the User of the PlayYourJob application which - based on your game preferences - is suitable for mapping soft skills and/or returning performance data on specific skills.
  </p>
  <h6> 9. WHERE AND FOR HOW LONG WILL MY DATA BE STORED? </h6>
  <p>
  Your data will be stored in the "cloud", on servers located within the European Union, and will be kept for the time necessary to achieve the purposes indicated.
  </p>
  <p>
  If you are a candidate, your data will be kept for the period strictly necessary to achieve the purposes for which they were collected and in any case for a period not exceeding 3 years. After the aforementioned term, your personal data will be deleted.
  </p>
  <p>
  If you are an employee, your data will be stored for the duration of the employment relationship and also thereafter for the fulfilment of all legal obligations. After the aforementioned term, your personal data will be deleted.
  </p>
  <h6> 10. WHAT ARE MY RIGHTS UNDER CURRENT PRIVACY LEGISLATION?</h6>
  <p>
  You are entitled to:
  </p>
  <ul>
  <li>access your personal data and the information related thereto;</li>
  <li>correct inaccurate data or complete incomplete data;</li>
  <li>possibly delete your personal data (upon the occurrence of one of the conditions indicated in art.17, paragraph 1, GDPR, and in compliance with the exceptions provided for in paragraph 3 of the same article), without prejudice to the provisions of item 9 of this information document;</li>
  <li>possibly limit the processing of your personal data;</li>
  <li>request and obtain from the Data Controller your personal data in a structured format that can be read by an automated device, also in order to communicate these data to another data controller;</li>
  <li>object at any time to the processing of your personal data in the event of particular situations concerning you;</li>
  <li>withdraw consent to the processing;</li>
  <li>to exercise these rights, you can contact the Data Controller by sending an email to learning.development@bracco.com or by ordinary post to the following address: Bracco Imaging spa, via Folli, 50, 20134 Milan, c/a Human Resources – People Development.</li>
  <li>In any case, the interested party can always lodge a complaint with the Guarantor for the Protection of Personal Data (website http://www.garanteprivacy.it/).</li>
  </ul>
`,
};
