import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./translations/en";
import it from "./translations/it";
import es from "./translations/es";

const resources = { en, it, es };

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "it",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

//export default {};

/* i18next.changeLanguage("en").then((t) => {
  console.log(t("key")); // -> same as i18next.t
}); */
